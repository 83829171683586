<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Gelen Bildirimler - Bireysel(SMS-Mobil)"
            icon="mdi-bell-check"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          />
          <v-row dense>
            <v-col>
              <v-btn
                class="me-1 mb-1"
                color="error"
                outlined
                dark
                small
                @click="checkMarked()"
              >
                Okundu Olarak İşaretle
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'communication.user-notifications.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.text="{ value }">
          <rs-table-cell-string :value="value" :limit="50" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.created_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_read="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.notification_channels="{ item }">
          {{ item.notification_channels.join(", ") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport } from "@/view/mixins";

export default {
  name: "UserNotificationList",
  mixins: [filtersToURL, hasExport],
  computed: {
    ...mapGetters(["notificationChannelListIds"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      titleBarAttrs: {
        exportUrl: () => `/user-notifications`,
        exportParams: this.getParams,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Metin",
          value: "text",
          searchable: "text",
        },
        {
          text: "Bildirim Türü",
          value: "notification_channels",
          searchable: "multiselect",
          options: () => this.notificationChannelListIds,
        },
        {
          text: "Tarih",
          value: "created_at",
          searchable: "date",
        },
        {
          text: "Okundu Mu?",
          value: "is_read",
          searchable: "yesNo",
          align: "center",
        },
      ],
    };
  },
  methods: {
    handleEditClick() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "communication.user-notifications.edit",
        params: {
          id: this.selectedItems[0].id,
        },
      });
    },
    checkMarked() {
      if (this.isLoading || !this.selectedItems.length) {
        return false;
      }

      let canForward = true;

      const params = {
        ids: this.selectedItems.map((item) => {
          if (item.is_read) {
            canForward = false;
          } else {
            return item.id;
          }
        }),
      };

      if (!canForward) {
        this.$toast.error(
          "Seçtiğiniz kayıtlardan bir veya bir kaç tanesi zaten okundu olarak işeretlenmiş"
        );
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`users-notifications/mark-as-read`, params)
        .then(() => {
          this.$toast.success(this.$t("saved"));
          setTimeout(() => this.loadList());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadList() {
      if (this.isLoading) {
        this.resetTable();
        return false;
      }

      this.resetTable();
      this.isLoading = true;

      const params = this.getParams();

      this.$api
        .query(`/users-notifications`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadList();
    this.generateExportColumns(this.headers);
  },
};
</script>
